import React from "react";
import { FeatureCard, FeatureCardAlt } from "components";
import { StaticImage } from "gatsby-plugin-image";

const ReadyCashFeatures = () => {
  return (
    <div>
      <div className="mb-[100px]"></div>
      <FeatureCardAlt
        title="Agency banking"
        description="Use dependable channels such as, mobile app, web app, POS devices and USSD strings to offer different services to your customer such as, money transfers, Cash-in, Cash-out, BVN registration, hospital collections, account opening, gaming services and lots more."
        buttonText="Become an Agent"
        pageLink={{
          url: "https://aggregator.readycash.com.ng/backend/agent-registration",
          target: "_blank",
        }}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/readycash/agency-banking.jpg"
            alt="features"
          />
        }
        anchor="agency-banking"
      />

      <FeatureCard
        title="Bills payment"
        description="Earn commission by providing value-added services such as electricity bill payment, airtime and data recharge, Waec and Jamb registration, pay-TV, and other utility bills."
        buttonText="Get started"
        pageLink={{
          url: "https://aggregator.readycash.com.ng/backend/agent-registration",
          target: "_blank",
        }}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/readycash/bills-payment.jpg"
            alt="features"
          />
        }
        anchor="bills-payment"
      />

      <FeatureCardAlt
        title="Become a ReadyCash agent and experience growth while impacting your community"
        buttonText="Get started"
        pageLink={{
          url: "https://aggregator.readycash.com.ng/backend/agent-registration",
          target: "_blank",
        }}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/readycash/agent-app.jpg"
            alt="features"
          />
        }
      />
    </div>
  );
};

export { ReadyCashFeatures };
