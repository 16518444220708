import { Br, PageHeader } from "components";
import React from "react";

const ReadyCashPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          Bringing financial services to
          <Br on="desktop" />
          the underbanked through an
          <Br on="desktop" />
          <span className="text-primary">agent distribution network.</span>
        </>
      }
      subheading={
        <>
          Contribute to your community by offering financial and digital banking
          solutions
          <Br on="desktop" />
          while increasing your daily income.
        </>
      }
      button={{
        text: "Become an Agent",
        href: {
          url: "https://aggregator.readycash.com.ng/backend/agent-registration",
          target: "_blank",
          rel: "noreferrer",
        },
      }}
    />
  );
};

export { ReadyCashPageHeader };
