import * as React from "react";

import { Layout } from "components";
import {
  ReadyCashPageHeader,
  ReadyCashFeatures,
} from "templates/solutions/readycash";

const ReadyCashPage = () => (
  <Layout title="ReadyCash">
    <ReadyCashPageHeader />
    <ReadyCashFeatures />
  </Layout>
);

export default ReadyCashPage;
